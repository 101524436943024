.react-calendar {
    width: 49%;
    height: 100%;
    background-color: #fff;
    color: white;
    border: 1px solid var(--vai-color-border-selected);
    box-shadow: var(--vai-shadow);
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
}
.react-calendar__navigation button {
    color: #006795;
    min-width: 44px;
    background: none;
    font-size: 16px;
    margin-top: 8px;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #f8f8fa;
}
.react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
}
abbr[title] {
    text-decoration: none;
}
/* .react-calendar__month-view__days__day--weekend {
color: #d10000;
} */

.react-calendar__month-view__days__day {
    color: #0081af;
}

.react-calendar__month-view__days__day--weekend {
    color: #0081af;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background: #006795;
    border-radius: 6px;
    font-weight: bold;
    color: white;
}

.react-calendar__tile--active {
    background: #006795;
    border-radius: 6px;
    font-weight: bold;
    color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #006795;
    color: white;
}

.react-calendar__tile:disabled {
    background-color: #f0f0f0;
    color: black;
}

.bar {
    margin-left: auto;
    margin-right: auto;
    height: 3px;
    width: 20px;
    margin-top: 2px;
  }
  
.no_data {
    background-color: #df1616;
  }
  
.valid {
    background-color: #33b28e;
  }

.light_warning {
    background-color: #e0d863;
  }

.warning {
    background-color: #ff9600;
  }

.grey {
    background-color: #cfcfcf;
  }

.vai-checkbox {
    position : relative;
    box-sizing: border-box;
    vertical-align: top;
    font: var(--vai-text-body);
    font-size: 100%;
    line-height: 3px;
    color: var(--vai-color-fg);
}

.vai-checkbox__checkbox[type='checkbox'] {
    width: 12px;
    height: 12px;
    margin-right: 0;
    margin-left: 0;
    opacity: 0;
    vertical-align: bottom;
    box-sizing: inherit;
    position: relative;
    cursor: pointer;
}

.vai-checkbox__checkbox--checked {
    width: 12px;
    height: 12px;
    margin-right: 0;
    margin-left: 0;
    opacity: 0;
    vertical-align: bottom;
    box-sizing: inherit;
    position: relative;
    cursor: pointer;
}

.vai-checkbox:before, .vai-checkbox:after {
    width: 12px;
    height: 12px;
}

.vai-checkbox:after {
    top: 0;
    left: 0;
}

.vai-checkbox__checkbox:before {
    top: 2px;
    border: 1px solid var(--vai-checkbox-color-border);
    background-color: var(--vai-color-bg);
    border-radius: var(--vai-checkbox-border-radius);
}

.vai-checkbox__checkbox:after {
    top: 2px;
    left: 0px;
}