.fallbackWrapper {
  height: 100%;
  max-height: 100vh;
  width: 100%;
  max-width: 100vw;
  text-align: center;
  background: #c00;
  color: #fff;
  box-sizing: border-box;
  cursor: help;
}

.fallbackSvg {
  fill: currentColor;
  flex: 1 1 auto;
  max-height: 100px;
}
