.pageSpinner {
  display: flex;
  width: 100%;
  align-items: center;
  height: 90vh;
}

.spinner {
  margin: auto;
}
